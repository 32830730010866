<template>
	<div id="rule">
		<index>
			<el-breadcrumb>
				<el-breadcrumb-item>教务</el-breadcrumb-item>
				<el-breadcrumb-item>教师排课</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">{{title}}</a></el-breadcrumb-item>
			</el-breadcrumb>
			<el-row :gutter="20" style="margin-top: 15px;">
				<el-col :span="18">
					<div class="content-left">
						<div class="head u-f-item u-f-jsb">
							<div class="u-f-item">
								<div class="lable u-f-item">
									<div class="u-f-item">
										周课时:0节
									</div>
									<span>|</span>
									<div class="u-f-item">
										<i class="iconfont icon-yuandian1"></i>
										行政班:0节
									</div>
									<span>|</span>
									<div class="u-f-item">
										<i class="iconfont icon-yuandian1"></i>
										走班:0节
									</div>
									<span>|</span>
									<div class="u-f-item">
										<i class="iconfont icon-yuandian1"></i>
										班会:0节
									</div>
									<span>|</span>
									<div class="u-f-item">
										<i class="iconfont icon-yuandian1"></i>
										自习:0节
									</div>
									<span>|</span>
									<div class="u-f-item">
										<i class="iconfont icon-yuandian1"></i>
										活动:0节
									</div>
								</div>
							</div>
							<div>
								<el-button type="primary" size="mini" @click="save">保存</el-button>
							</div>
						</div>
						<div class="content">
							<div class="row-data u-f-justify">
								<span class="cells u-f-justify u-f-column"></span>
								<span class="cells u-f-justify u-f-column" v-for="(item,index) in attend_day" :key="index">{{item}}</span>
							</div>
							<div class="row-data two u-f-justify" v-for="(items,index) in info" :key="index" style="height: 3.54166667vw;">
								<span class="cells u-f-justify u-f-column">
									{{items.node}}<br/>
									<span class="first_cell_title">{{items.start}}-{{items.end}}</span>
								</span>
								<span v-if="propertyIndex==0" class="cells u-f-justify u-f-column bg1" @click="listTap(index,i)" :class="item.checked?'on':''" v-for="(item,i) in items.list">行政班</span>
								<span v-if="propertyIndex==1" class="cells u-f-justify u-f-column bg1" @click="listTap(index,i)"  :class="item.checked?'on1':''" v-for="(item,i) in items.list">行政班</span>
								<span v-if="propertyIndex==2" class="cells u-f-justify u-f-column bg1" @click="listTap(index,i)"  :class="item.checked?'on2':''" v-for="(item,i) in items.list">行政班</span>
								<span v-if="propertyIndex==3" class="cells u-f-justify u-f-column bg1" @click="listTap(index,i)"  :class="item.checked?'on3':''" v-for="(item,i) in items.list">行政班</span>
								<span v-if="propertyIndex==4" class="cells u-f-justify u-f-column bg1" @click="listTap(index,i)"  :class="item.checked?'on4':''" v-for="(item,i) in items.list">行政班</span>
							</div>
						</div>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="content-right">
						<div class="head u-f-item">
							<div class="u-f1 u-f-justify head-item" @click="tabTap(0)" :class="tabIndex==0?'active':''">排课属性</div>
							<span style="color: #A4A4A4;">|</span>
							<div class="u-f1 u-f-justify head-item" @click="tabTap(1)" :class="tabIndex==1?'active':''">场地规则</div>
						</div>
						<template v-if="tabIndex==0">
							<div class="property">
								<div>课堂属性</div>
								<div class="property-list">
									<div class="property-item" @click="propertyTap(index)" :class="propertyIndex==index?'active':''" v-for="(item,index) in propertyList" :key="index">{{item}}</div>
								</div>
							</div>
							<template v-if="propertyIndex==0">
								<div class="subject">
									<div class="subject-head u-f-item u-f-jsb">
										<div>禁排学科</div>
										<div class="u-f-item">
											<el-button size="mini" @click="openDialog(1)">选择</el-button>
											<el-button size="mini" disabled>清空</el-button>
										</div>
									</div>
									<div class="subject-list-box">
										<div class="subject-list">
											<blockquote style="magrin: 0" v-if="info[row_index]&&info[row_index].list[col_index].disabled_discipline">
												<div class="subject-item" v-for="(item, index) in info[row_index].list[col_index].disabled_discipline" :key="index">语文<i class="el-icon-error"></i></div>
											</blockquote>
										</div>
									</div>
								</div>
								<div class="personnel">
									<div class="personnel-head u-f-item u-f-jsb">
										<div>禁排学科</div>
										<div class="u-f-item">
											<el-button size="mini" @click="openDialog(2)">选择</el-button>
											<el-button size="mini" disabled>清空</el-button>
										</div>
									</div>
									<div class="personnel-list-box">
										<div class="personnel-list">
											<blockquote style="magrin: 0" v-if="info[row_index]&&info[row_index].list[col_index].disabled_teacher">
												<div class="personnel-item" v-for="(item, index) in info[row_index].list[col_index].disabled_teacher" :key="index">语文<i class="el-icon-error"></i></div>
											</blockquote>
										</div>
									</div>
								</div>
							</template>
							<template v-if="propertyIndex==1">
								<div style="height: 67.5vh;background-color: #fff;"></div>
							</template>
							<template v-if="propertyIndex==2">
								<div style="height: 67.5vh;background-color: #fff;"></div>
							</template>
							<template v-if="propertyIndex==3">
								<div style="height: 67.5vh;background-color: #fff;"></div>
							</template>
							<template v-if="propertyIndex==4">
								<div style="height: 67.5vh;background-color: #fff;"></div>
							</template>
						</template>
						<template v-if="tabIndex==1">
							<div class="u-f-justify u-f-column" style="height: 79.5vh;background-color: #ffffff;">
								<img src="../../assets/image/nothing.png" alt="" style="width: 5vw;">
								<div style="color: #A4A4A4;margin-top: 15px;">暂无数据</div>
							</div>
						</template>
					</div>
				</el-col>
			</el-row>
			<el-dialog :title="VisibleTitle" :visible.sync="addVisible" width="500px" :before-close="handleClose">
				<blockquote style="margin: 0" v-if="type==1">
					<div class="edit-box">
						<div class="u-f-item input-box">
							<div class="input-lebal">房间</div>
							<el-select v-model="school_place_room_id" multiple placeholder="请选择房间" style="width: 80%;">
								<el-option v-for="item in roomList" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</div>
					</div>
				</blockquote>
				<blockquote style="margin: 0" v-else>
					<div class="edit-box">
						<div class="u-f-item input-box">
							<div class="input-lebal">房间</div>
							<el-select v-model="school_place_room_id" multiple placeholder="请选择房间" style="width: 80%;">
								<el-option v-for="item in roomList" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</div>
					</div>
				</blockquote>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="closeVisible" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submit">确 定</el-button>
				</span>
			</el-dialog>
		</index>
	</div>
</template>

<script>
	import index from '@/components/common/index.vue';
	export default {
		components: {
			index
		},
		data(){
			return{
				id:'',
				title:'',
				tabIndex:0,
				propertyList:['行政班','走班','班会','自习','活动'],
				propertyIndex:0,
				info:[],
				attend_day:[],
				row_index: 0,
				col_index: 0,
				type: '',
				VisibleTitle: '',
				addVisible: false,
				subList: []
			}
		},
		mounted() {
			this.id = this.$route.query.id;
			this.title = this.$route.query.title;
			this.getCourseRuleSetting()
		},
		methods:{
			tabTap(index){
				this.tabIndex = index;
			},
			propertyTap(index){
				this.propertyIndex = index;
			},
			getCourseRuleSetting(){
				this.$api.paike.getCourseRuleSetting({
					id:this.id
				}).then(res=>{
					if(res.data.code==1){
						let data = res.data.data.curriculum;
						for(let i in data){
							if(i==0){
								for(let j in data[i].list){
									if(j==0){
										data[i].list[j].checked = true;
									}else{
										data[i].list[j].checked = false;
									}
								}
							}
						}
						this.subList = data;
						this.info = data;
						this.attend_day = res.data.data.attend_day;
					}
				})
			},
			listTap(i,j){
				for(let a in this.info){
					for(let b in this.info[a].list){
						this.info[a].list[b].checked = false;
					}
				}
				this.row_index = i;
				this.col_index = j;
				this.info[i].list[j].checked = true;
				
			},
			openDialog(type){
				this.type = type;
			},
			save(){
				this.$api.paike.saveCourseRuleSetting(this.id,{curriculum: this.subList}).then(res=>{
					if(res.data.code==1){
						this.$message.success('保存成功')
						this.getCourseRuleSetting()
					}else{
						this.$message.error(res.data.msg)
					}
				})
			}
		}
	}
	
</script>

<style lang="scss">
	#rule{
		.content-left{
			.head {
				background-color: #ffffff;
				padding: 0 20px;
				height: 60px;
				margin-bottom: 5px;
				.lable {
					background-color: #f6f8fb;
					height: 30px;
					font-size: 12px;
					padding: 0 15px;
					line-height: 30px;
					margin-right: 20px;
					div:nth-of-type(2) {
						i {
							color: #38c3a1;
						}
					}
					div:nth-of-type(3) {
						i {
							color: #ff7a65;
						}
					}
					div:nth-of-type(4) {
						i {
							color: #ffc904;
						}
					}
					div:nth-of-type(5) {
						i {
							color: #a190f7;
						}
					}
					div:nth-of-type(6) {
						i {
							color: #FF7A65;
						}
					}
					span {
						color: #eeeeee;
						padding: 0 5px;
					}
				}
			}
			.content {
				background-color: #ffffff;
				height: 76.5vh;
				padding: 15px 10px 15px 20px;
				.row-data{
					border-right: 1px solid #E8EAEC;
					border-left: 1px solid #E8EAEC;
					height: 2.70833333vw;
					min-height: 27.73333333px;
					box-sizing: border-box;
					background-color: #F6F8FB;
					.cells{
						flex: 1;
						box-sizing: border-box;
						border-left: 1px solid #E8EAEC;
						border-bottom: 1px solid #E8EAEC;
						position: relative;
						cursor: pointer;
						height: 100%;
						font-size: 0.625vw;
						.first_cell_title{
							margin-top: 8px;
							color: #8D9DA6;
						}
					}
					.cells:first-child{
						flex: 0;
						width: 5.83333333vw;
						min-width: 80px;
						height: 100%;
						border-left: none;
					}
				}
				.bg1{
					background-color: #FFFFFF;
					color: #38C3A1;
				}
				.on{
					background-color: #E2F7F4;
				}
				.on1{
					background-color: #FFF8EB;
					color: #FFC904;
				}
				.on2{
					background-color:#F5F2FF;
					color: #A190F7;
				}
				.on3{
					background-color: #E4FBFF;
					color: #1EC5E2;
				}
				.on4{
					background-color: #FFF4F3;
					color: #FF7A65;
				}
				.bg1:hover{
					background-color: #E2F7F4;
					color: #38C3B8;
				}
				.row-data:first-child{
					border-top: 1px solid #E8EAEC;
				}
			}
		}
		.content-right{
			.head {
				background-color: #e4e9f5;
				height: 65px;
				line-height: 65px;
				padding: 0 20px;
				.active{
					position: relative;
					color: #4E6EF2;
				}
				.active:after{
					content: '';
					height: 2px;
					width: 60px;
					background-color: #4E6EF2;
					position: absolute;
					bottom: 0;
				}
			}
			.property{
				background-color: #ffffff;
				padding: 30px 10px 25px 25px;
				margin-bottom: 5px;
				.property-list{
					display: flex;
					flex-wrap: wrap;
					margin-top: 10px;
					.property-item{
						margin-right: 10px;
						margin-bottom: 8px;
						background-color: #F3F3F3;
						padding: 5px 0;
						width: 3.445vw;
						text-align: center;
						border-radius: 3px;
						cursor: pointer;
					}
					.active{
						color: #6370EE;
					}
				}
			}
			.subject{
				height: 25vh;
				background-color: #ffffff;
				.subject-head{
					padding:10px 25px;
				}
				.subject-list-box{
					height: 20vh;
					overflow-y: auto;
					.subject-list{
						display: flex;
						flex-wrap: wrap;
						padding: 15px 15px 0 25px;
						.subject-item{
							width: 5.6vw;
							text-align: center;
							padding: 6px 0;
							border-radius: 3px;
							background-color: #F6F8FB;
							margin-right: 10px;
							margin-bottom: 10px;
							position: relative;
							i{
								position: absolute;
								right: 0.5vw;
								top: 0.5vw;
								color: red;
								opacity: 0;
							}
						}
						.subject-item:hover{
							color: #6370EE;
							i{
								opacity: 1;
							}
						}
					}
				}
				
			}
			.personnel{
				height: 42vh;
				background-color: #ffffff;
				margin-top: 5px;
				.personnel-head{
					padding:10px 25px;
				}
				.personnel-list-box{
					min-height: 37vh;
					overflow-y: auto;
					.personnel-list{
						display: flex;
						flex-wrap: wrap;
						padding: 15px 15px 0 25px;
						.personnel-item{
							width: 5.6vw;
							text-align: center;
							padding: 6px 0;
							border-radius: 3px;
							background-color: #F6F8FB;
							margin-right: 10px;
							margin-bottom: 10px;
							position: relative;
							i{
								position: absolute;
								right: 0.5vw;
								top: 0.5vw;
								color: red;
								opacity: 0;
							}
						}
						.personnel-item:hover{
							color: #6370EE;
							i{
								opacity: 1;
							}
						}
					}
				}
				
			}
		}
	}
</style>
